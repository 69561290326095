





































import Vue from "vue";
import * as _ from "lodash";
import db from "@/firebase/db";
import fb from "firebase/app";
import DBHelper from "@/tscript/dbHelper";
import Header from "@/components/global/Header.vue";
import { mapState, mapActions } from "vuex";

export default Vue.extend({
  name: "HomeGroup",
  components: {
    Header,
  },
  data() {
    return {
      dbHelper: new DBHelper(db),
      notificationsList: new Array(),
      loading: false,
      group: {} as any,
      valid: true,
    };
  },
  methods: {
    ...mapActions("analytics", ["logAction"]),
    async loadData() {
      this.loading = true;
      const network = await this.dbHelper.getDocFromCollectionWithWhere(
        "network",
        {
          user_id: this.userData.id,
        }
      );

      if (network?.waiting?.length) {
        const usersAskingList =
          await this.dbHelper.getAllDataFromCollectionFromIds(
            "users",
            network.waiting
          );
        this.notificationsList = this.notificationsList.concat(usersAskingList);
      }
      this.loading = false;
    },
    async accept(userId: string) {
      const FieldValue = fb.firestore.FieldValue;
      await this.dbHelper.updateDataToCollection(
        "network",
        this.userData.network.id,
        {
          current: FieldValue.arrayUnion(userId),
        }
      );
      const where: any = { user_id: userId };
      const network: any = this.dbHelper.getDocFromCollectionWithWhere(
        "network",
        where
      );
      await this.dbHelper.updateDataToCollection("network", network.id, {
        asking: FieldValue.arrayUnion(this.userData.id),
      });
    },
  },
  created() {
    this.logAction({ event_name: "home_notifications" });
    this.loadData();
  },
  computed: {
    ...mapState("user", ["userData"]),
  },
});
