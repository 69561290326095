










































import MycButton from "./buttons/MycButton.vue";
export default {
  components: {MycButton},
  props: {
    title: {
      default: "",
    },
    leftButton: {
      default: null,
    },
    rightButtonSecondary: {
      default: null,
    },
    rightButtonPrimary: {
      default: null,
    },
    rightButtonIconSecondary: {
      default: "",
    },
    rightButtonIconPrimary: {
      default: "",
    },
  },
};
